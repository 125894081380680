import React from 'react';
import { graphql, Link } from 'gatsby';
import Layout from '../components/Layout';
import SEO from '../components/SEO';
import { ArrowLeftIcon } from '@heroicons/react/solid';

export const query = graphql`
  query ($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        date(formatString: "DD.MM.YYYY")
        slug
        title
      }
    }
  }
`;

export default function Template({ data }) {
  const { frontmatter, html } = data.markdownRemark;
  return (
    <Layout>
      <SEO title={frontmatter.title} />
      <div className="container max-w-4xl pt-32 pb-28">
        <Link to="/support" className="flex items-center hover:underline">
          <ArrowLeftIcon className="h-5 w-5 mr-2" /> Tilbage til support
        </Link>
        <div className="border-b-4 pb-6 my-6 lg:pb-8 lg:my-12 border-gray-200">
          <h1 className="heading-sm">{frontmatter.title}</h1>
          <p className="text-gray-500 text-sm font-semibold mt-4">
            Sidst opdateret {frontmatter.date}
          </p>
        </div>
        <div
          className="prose lg:prose-lg"
          dangerouslySetInnerHTML={{ __html: html }}
        />
        <div className="mt-28 p-6 bg-indigo-50 rounded-md text-indigo-900">
          <h3 className="text-xl lg:text-2xl font-semibold mb-1">
            Har du brug for lidt ekstra hjælp?
          </h3>
          <p>
            Hvis ikke du fandt svar på det du søgte, kan du læse en af vores
            andre artikler eller kontakte vores support direkte.
          </p>
          <div className="flex mt-4">
            <Link to="/support" className="btn-sm mr-2">
              Se alle artikler
            </Link>
            <a href="mailto:support@get-sails.dk" className="btn-sm-secondary">
              Kontakt support
            </a>
          </div>
        </div>
      </div>
    </Layout>
  );
}
